<template>
	<DataTable
		type="leads"
		:featuresMap="{
			add: features.addLead,
			edit: features.editLead,
			delete: features.deleteLead,
			addFiles: true,
		}"
		title="Leads"
		:quickFilters="quickFilters"
		:filtersConfig="filtersConfig"
		:getItems="getLeads"
		:patchItem="patchLead"
		:deleteItem="deleteLead"
		:actions="['comments', 'tasks', 'files', 'timeline', 'view', 'edit', 'delete']"
		sortByOverwrite="_created"
	>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<LeadForm :lead="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable";
import LeadForm from "../components/LeadForm";

import { getLeads, patchLead, deleteLead } from "../api/leads";

export default Vue.extend({
	name: "Leads",
	components: { DataTable, LeadForm },
	computed: mapState(["features"]),
	data() {
		return {
			quickFilters: [
				{
					name: "Leads",
					value: "leads",
					icon: "mdi-account-switch",
					filters: { stage: ["recruiterAssigned"] },
				},
				{
					name: "Em Seguimento",
					value: "contacted",
					icon: "mdi-phone-in-talk",
					filters: {
						stage: [
							"recruiterAccepted",
							"contactsContacted",
							"interviewMissedByRecruiter",
							"interviewMissedByLead",
							"presentationMissedByRecruiter",
							"presentationMissedByLead",
							"approvalMissedByDirector",
							"approvalMissedByLead",
						],
					},
				},
				{
					name: "Agendados",
					value: "scheduled",
					icon: "mdi-calendar",
					filters: {
						stage: ["interviewScheduled", "presentationScheduled", "approvalScheduled"],
					},
				},
				{
					name: "Entrevistados",
					value: "interviews",
					icon: "mdi-account-check",
					filters: { stage: ["interviewDone", "presentationDone", "approvalDone"] },
				},
				{
					name: "Recrutados",
					value: "recruited",
					icon: "mdi-star",
					filters: { stage: ["recruited"] },
				},
				{
					name: "Anulado",
					value: "declined",
					icon: "mdi-close",
					filters: {
						stage: [
							"recruiterRejected",
							"contactsContactedByLead",
							"contactsContactedByRecruiter",
							"interviewDoneByLead",
							"interviewDoneByRecruiter",
							"presentationDoneByLead",
							"presentationDoneByRecruiter",
							"approvalDoneByLead",
							"approvalDoneByRecruiter",
						],
					},
				},
			],

			filtersConfig: [
				{ type: "autocomplete", label: "Recrutadores", value: "recruiter", items: "recruiters" },
				{ type: "autocomplete", label: "Etapas", value: "stage", items: "config.stages", itemValue: "key" },
				{ type: "autocomplete", label: "Fontes", value: "source", items: "config.sources", itemValue: "key" },
				{
					type: "buttonToggle",
					value: "experient",
					options: [
						{ value: true, label: "Experiente" },
						{ value: "null", label: "Tudo" },
						{ value: false, label: "Não Experiente" },
					],
				},
				{
					type: "autocomplete",
					label: "Experiência",
					value: "experienceTypes",
					items: "config.experienceTypes",
					itemValue: "key",
				},
			],
		};
	},
	methods: { getLeads, patchLead, deleteLead },
});
</script>
