<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<v-text-field
						outlined
						v-model="name"
						label="Nome"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-select
						outlined
						v-model="gender"
						:items="config.genders"
						item-text="name"
						item-value="key"
						label="Género"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-text-field
						outlined
						v-model="personalEmail"
						label="Email Pessoal"
						:rules="[requiredContact, validEmail]"
						:hint="phone ? '' : 'Obrigatório'"
						persistent-hint
					/>
					<v-row class="twoColRow">
						<v-col>
							<v-text-field
								outlined
								v-model="phone"
								label="Nº de telefone"
								:rules="[requiredContact, validPhone]"
								:hint="personalEmail ? '' : 'Obrigatório'"
								persistent-hint
							/>
						</v-col>
						<v-col>
							<v-text-field outlined v-model="phone2" label="Nº de telefone 2" :rules="[validPhone]" />
						</v-col>
					</v-row>
					<div v-if="lead">
						<v-menu
							v-model="showBirthdayPicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="birthday"
									label="Data de Nascimento"
									v-bind="attrs"
									v-on="on"
									readonly
								/>
							</template>
							<v-date-picker
								ref="birthdayPicker"
								v-model="birthday"
								color="primary"
								:max="new Date().toISOString().substr(0, 10)"
								@input="showBirthdayPicker = false"
							/>
						</v-menu>
					</div>
					<v-autocomplete
						outlined
						v-model="country"
						:items="countries"
						item-text="name"
						item-value="value"
						label="País"
						hide-selected
					/>
				</v-col>
				<v-divider vertical />
				<br /><br />
				<v-col style="margin: 40px; max-width: 400px">
					<div v-if="image && !editImage" @click="editImage = true" style="margin-bottom: 20px">
						<v-badge color="primary" icon="mdi-pencil" overlap>
							<img :src="getFileUrl(this.image)" height="145px" />
						</v-badge>
					</div>
					<div v-else style="margin-bottom: 30px">
						<VueFileAgent
							accept="image/*"
							helpText="Escolha uma imagem ou faça drag & drop"
							:errorText="{
								type: 'Tipo de ficheiro inválido. Só são aceites PDFs',
							}"
							v-model="file"
							class="imageFile"
						>
						</VueFileAgent>
					</div>
					<v-select
						outlined
						v-model="source"
						:items="config.sources"
						item-text="name"
						item-value="key"
						label="Fonte"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-autocomplete
						v-if="source === 'reference' || source === 'teamLeader'"
						outlined
						v-model="reference"
						:items="staff"
						item-text="name"
						item-value="_id"
						label="Referência"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
						clearable
					/>
					<v-select
						outlined
						v-model="office"
						:items="allOffices"
						item-text="name"
						item-value="key"
						label="Agência"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-autocomplete
						outlined
						v-model="recruiter"
						:items="recruiters"
						item-text="name"
						item-value="_id"
						label="Recrutador"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-textarea outlined v-model="observations" label="Observações" height="145px" hide-details />
					<v-checkbox outlined v-model="experient" label="Experiência na Área" />
					<v-select
						v-if="experient"
						outlined
						v-model="experienceTypes"
						:items="config.experienceTypes"
						item-text="name"
						item-value="key"
						label="Tipo de Experiência"
						small-chips
						multiple
					/>
					<v-textarea v-if="experient" outlined v-model="experience" label="Experiência" height="145px" />
				</v-col>
			</v-row>
			<v-btn :disabled="loading" type="submit" color="primary">{{ lead ? "Atualizar" : "Adicionar" }}</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { countries } from "countries-list";
import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { addLead, editLead } from "../api/leads";
import { addFiles } from "../api/files";

import { formatDate, validateCC, getFileUrl } from "../utils/utils";

export default Vue.extend({
	name: "LeadForm",
	props: ["lead", "onClose"],
	async created() {
		await this.handleGetUsers();

		this.office = this.selectedOffices[0];
		if (this.user.role === "recruiter") this.recruiter = this.user._id;

		if (this.lead) this.populateLead(this.lead);

		this.countries = Object.entries(countries).map(([key, value]) => ({ name: value.name, value: key }));
	},
	watch: {
		lead: function (newVal) {
			if (newVal) {
				this.populateLead(newVal);
			} else {
				this.resetForm();
			}

			this.$refs.form.resetValidation();
		},
		showBirthdayPicker(val) {
			val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = "YEAR"));
		},
	},
	computed: {
		...mapState(["user", "company", "token", "allOffices", "selectedOffices", "config"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;

		return {
			loading: false,

			required: value => !!value || "Obrigatório",
			requiredContact: () => (!this.personalEmail && !this.phone ? "Obrigatório" : true),
			validEmail: value => !value || (value && emailRegex.test(value)) || "Email inválido",
			validPhone: value =>
				!value || (value && (value.includes("+") ? true : value.length === 9)) || "Telefone inválido",
			validCc: value => !value || (value && validateCC(value)) || "Cartão de Cidadão inválido",
			validNif: value => !value || value.toString().length === 9 || "NIF inválido",
			validPostalCode: value => !value || postalCodeRegex.test(value) || "Código postal inválido",

			countries: [],
			recruiters: [],
			staff: [],
			showBirthdayPicker: false,
			file: null,
			editImage: false,

			source: "",
			reference: null,
			office: null,
			recruiter: null,
			observations: "",
			experient: false,
			experienceTypes: [],
			experience: "",

			image: "",
			name: "",
			gender: "",
			birthday: "",
			phone: "",
			phone2: "",
			personalEmail: "",
			country: "PT",
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		getFileUrl,
		populateLead(newVal) {
			this.source = newVal.source;
			this.reference = newVal.reference ? newVal.reference._id : null;
			this.office = newVal.office;
			this.recruiter = newVal.recruiter && newVal.recruiter._id;
			this.observations = newVal.observations;
			this.experient = newVal.experient;
			this.experienceTypes = newVal.experienceTypes ? newVal.experienceTypes : [];
			this.experience = newVal.experience;

			this.image = newVal.image;
			this.name = newVal.name;
			this.gender = newVal.gender;
			this.birthday = newVal.birthday ? formatDate(newVal.birthday, "YYYY-MM-DD") : "";
			this.phone = newVal.phone;
			this.phone2 = newVal.phone2;
			this.personalEmail = newVal.personalEmail;
			this.country = newVal.country;

			this.file = null;
			this.editImage = false;
		},
		resetForm() {
			this.source = "";
			this.reference = null;
			this.office = null;
			this.recruiter = null;
			this.observations = "";
			this.experient = false;
			this.experienceTypes = [];
			this.experience = "";

			this.image = "";
			this.name = "";
			this.gender = "";
			this.birthday = "";
			this.phone = "";
			this.phone2 = "";
			this.personalEmail = "";
			this.country = "PT";

			this.file = null;
			this.editImage = false;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.staff = response.data.users;
				this.recruiters = response.data.users.filter(u =>
					["recruiter", "expansionDirector", "commercialDirector", "teamLeader", "broker", "coo", "ceo"].includes(
						u.role,
					),
				);
			}
		},
		isFormValid() {
			if (!this.$refs.form.validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			return true;
		},
		handleSubmit() {
			return this.lead ? this.handleEditLead() : this.handleAddLead();
		},
		async uploadFiles(id) {
			if (this.file) {
				const formData = new FormData();

				formData.append("files", this.file.file);
				formData.append("fileTypes", [this.file.ext]);
				formData.append("fileNames", [`/${this.file.file.name}`]);

				await addFiles("leads", id, "leads", formData, "profileImage");
			}

			this.file = null;
			this.editImage = false;
		},
		async handleAddLead() {
			const {
				source,
				reference,
				office,
				recruiter,
				observations,
				experient,
				experienceTypes,
				experience,
				name,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				country,
			} = this;

			if (!this.isFormValid()) return;

			this.loading = true;

			const response = await addLead({
				source,
				reference,
				office,
				recruiter,
				observations,
				experient,
				experienceTypes,
				experience,
				name: name.replace("\t", "").trim(),
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				country,
			});

			if (response.status === 201) {
				await this.uploadFiles(response.data._id);

				this.onClose();

				this.resetForm();

				this.openToast({ message: "Lead adicionada com sucesso", color: "green" });
			} else if (response.status === 409) {
				this.openToast({ message: "Lead já está registada", color: "red" });
			}

			this.loading = false;
		},
		async handleEditLead() {
			const {
				source,
				reference,
				office,
				recruiter,
				observations,
				experient,
				experienceTypes,
				experience,
				name,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				country,
			} = this;

			if (!this.isFormValid()) return;

			this.loading = true;

			const response = await editLead({
				_id: this.lead._id,
				source,
				reference,
				office,
				recruiter,
				observations,
				experient,
				experienceTypes,
				experience,
				name: name.replace("\t", "").trim(),
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				country,
			});

			if (response.status === 200) {
				await this.uploadFiles(response.data._id);

				this.onClose();

				this.resetForm();

				this.openToast({ message: "Lead atualizada com sucesso", color: "green" });
			} else if (response.status === 409) {
				this.openToast({ message: "Lead já está registada", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
