<template>
	<div id="leadDetail" v-if="lead">
		<v-row style="max-width: 1800px" class="mx-auto">
			<v-col style="display: flex; gap: 10px; flex-wrap: wrap; width: 600px; flex-grow: 1">
				<div>
					<h1>Detalhes</h1>
					<br />
					<div style="display: flex; gap: 10px; flex-direction: column">
						<div style="display: flex; gap: 10px; flex-wrap: wrap; flex-grow: 1">
							<v-card style="width: 400px; flex-grow: 1">
								<v-list two-line style="text-align: left">
									<v-list-item v-if="lead.name">
										<v-list-item-icon>
											<v-icon color="primary">mdi-account</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Nome</v-list-item-subtitle>
											<v-list-item-title>{{ lead.name }}</v-list-item-title>
										</v-list-item-content>
										<v-avatar v-if="lead.image" size="48">
											<img :src="getFileUrl(lead.image)" />
										</v-avatar>
										<v-avatar v-else color="primary" size="48">
											<span class="white--text headline" style="font-size: 1.5em">
												{{ formatAvatarName(lead.name) }}
											</span>
										</v-avatar>
									</v-list-item>
									<v-list-item v-if="lead.gender">
										<v-list-item-icon>
											<v-icon color="primary">mdi-gender-male-female</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Género</v-list-item-subtitle>
											<v-list-item-title>
												{{ config.genders.find(g => g.key === lead.gender).name }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.birthday">
										<v-list-item-icon>
											<v-icon color="primary">mdi-cake-variant</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Data de Nascimento</v-list-item-subtitle>
											<v-list-item-title>{{ formatDate(lead.birthday, "DD-MM-YYYY") }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider inset />
									<v-list-item v-if="lead.personalEmail">
										<v-list-item-icon>
											<v-icon color="primary">mdi-email</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Email Pessoal</v-list-item-subtitle>
											<v-list-item-title>
												<span v-if="lead.stage === 'recruiterAssigned'" class="error--text">
													Tome posse da lead para ver
												</span>
												<span v-else>{{ lead.personalEmail }}</span>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.phone">
										<v-list-item-icon>
											<v-icon color="primary">mdi-phone</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Telefone</v-list-item-subtitle>
											<v-list-item-title>
												<span v-if="lead.stage === 'recruiterAssigned'" class="error--text">
													Tome posse da lead para ver
												</span>
												<span v-else>{{ lead.phone }}</span>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.phone2">
										<v-list-item-icon>
											<v-icon color="primary">mdi-phone</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Telefone 2</v-list-item-subtitle>
											<v-list-item-title>{{ lead.phone2 }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.country">
										<v-list-item-icon>
											<v-icon tag="span">{{ countries[lead.country].emoji }}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>País</v-list-item-subtitle>
											<v-list-item-title>{{ countries[lead.country].name }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
							<v-card style="width: 400px; flex-grow: 1">
								<v-list two-line style="text-align: left">
									<v-list-item v-if="lead.source">
										<v-list-item-icon>
											<v-icon color="primary">mdi-source-branch</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Fonte</v-list-item-subtitle>
											<v-list-item-title>
												{{ config.sources.find(s => s.key === lead.source)?.name }}
											</v-list-item-title>
											<v-list-item-subtitle v-if="lead.reference">{{ lead.reference.name }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.office && allOffices.find(o => o.key === lead.office)">
										<v-list-item-icon>
											<v-icon color="primary">mdi-store</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Agência</v-list-item-subtitle>
											<v-list-item-title>
												{{ allOffices.find(o => o.key === lead.office)?.name }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.recruiter">
										<v-list-item-icon>
											<v-icon color="primary">mdi-account-switch</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Recrutador</v-list-item-subtitle>
											<v-list-item-title>{{ lead.recruiter ? lead.recruiter.name : "" }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.observations">
										<v-list-item-icon>
											<v-icon color="primary">mdi-format-align-left</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Observações</v-list-item-subtitle>
											<v-list-item-title class="text-wrap">{{ lead.observations }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.experient">
										<v-list-item-icon>
											<v-icon color="primary">mdi-home-assistant</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Experiência na Área</v-list-item-subtitle>
											<v-list-item-title>Sim</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="lead.experience">
										<v-list-item-icon>
											<v-icon color="primary">mdi-home-assistant</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-subtitle>Experiência</v-list-item-subtitle>
											<v-list-item-title>{{ lead.experience }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
							<Files
								:files="lead.files.filter(f => !f.type)"
								group="leads"
								:id="lead._id"
								:patchFunction="patchLead"
								:onSubmit="handleGetLead"
								style="width: 400px; flex-grow: 1"
							/>
						</div>
						<div style="display: flex; flex-wrap: wrap; gap: 10px">
							<Comments group="leads" :id="lead._id" :inline="true" style="width: 400px; flex-grow: 1" />
							<v-card style="width: 400px; flex-grow: 1">
								<v-card-title>Tarefas</v-card-title>
								<Tasks />
							</v-card>
						</div>
					</div>
				</div>
			</v-col>
			<v-col>
				<h1>Timeline</h1>
				<v-timeline align-top>
					<v-timeline-item v-if="!isClosed && features.addEventsToLead" icon="mdi-plus" large fill-dot>
						<template v-slot:icon>
							<v-icon class="white--text headline" @click="openLeadTimelineForm(null)">mdi-plus</v-icon>
						</template>
					</v-timeline-item>
					<br /><br />
					<v-timeline-item v-for="(event, i) in events" :key="i" :color="event.color" :icon="event.icon" fill-dot>
						<v-card>
							<v-card-title style="word-break: keep-all">{{ event.title }}</v-card-title>
							<v-card-subtitle align="left">
								{{ formatDate(event.date, event.step === 6 ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm") }}
							</v-card-subtitle>
							<v-card-text
								v-if="event.observations || event.justification"
								align="left"
								style="word-break: keep-all"
							>
								{{ event.justification }}
								<br />
								{{ event.observations }}
							</v-card-text>
							<DeletionDialog deletionText="este evento" :onConfirm="() => deleteLastEvent()">
								<template v-slot:activator="{ onClick }">
									<div @click="onClick" style="position: absolute; top: 5px; right: 22px; cursor: pointer">
										<v-badge v-if="i === 0 && event.canDelete" icon="mdi-close" color="error" />
									</div>
								</template>
							</DeletionDialog>
						</v-card>
					</v-timeline-item>
				</v-timeline>
			</v-col>
		</v-row>
		<v-btn
			v-if="['interviewDone', 'presentationScheduled'].includes(lead.stage)"
			rounded
			fixed
			bottom
			right
			:color="lead.airTableId ? '#006600' : 'dark-grey'"
			style="bottom: 85px; right: 15px; padding: 22px 10px; text-transform: capitalize"
		>
			<v-switch v-model="lead.airTableId" color="white" label="Whats App" @change="toggleAirTable" />
		</v-btn>
		<v-btn v-if="features.editLead" fab fixed bottom right color="primary" @click="openLeadForm">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-dialog v-model="showLeadForm" max-width="1000px">
			<LeadForm :lead="lead" :onClose="closeLeadForm" />
		</v-dialog>
		<v-btn
			v-if="!['toAssign', 'recruiterAssigned', 'recruiterAccepted', 'recruited'].includes(lead.stage)"
			fab
			fixed
			bottom
			right
			color="primary"
			@click="openContactForm"
			style="right: 85px"
		>
			<v-icon>mdi-phone</v-icon>
		</v-btn>
		<v-dialog v-model="showContactForm" width="500px">
			<LeadTimelineSideForm :lead="lead" formType="afterContact" :handleAddContact="handleAddContact" />
		</v-dialog>
		<v-dialog v-model="showLeadTimelineForm" max-width="1000px">
			<LeadTimelineForm :lead="lead" :overrideStep="overrideStep" :onClose="closeLeadTimelineForm" />
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { countries } from "countries-list";

import LeadForm from "../components/LeadForm";
import LeadTimelineSideForm from "../components/LeadTimelineSideForm";
import LeadTimelineForm from "../components/LeadTimelineForm";
import DeletionDialog from "../components/DeletionDialog";
import Files from "../components/Files";
import Comments from "../components/Comments";
import Tasks from "../components/Tasks";

import { getLead, patchLead, addEvents, deleteLastEvent, toggleAirTable } from "../api/leads";

import { formatDate, formatAvatarName, getFileUrl, getFileName } from "../utils/utils";

export default Vue.extend({
	name: "LeadDetail",
	components: {
		LeadForm,
		LeadTimelineSideForm,
		LeadTimelineForm,
		DeletionDialog,
		Files,
		Comments,
		Tasks,
	},
	created() {
		this.handleGetLead();

		this.countries = countries;
	},
	watch: {
		"$route.params.id": function () {
			this.handleGetLead();
		},
	},
	computed: {
		...mapState(["company", "token", "allOffices", "config", "features"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		return {
			countries: [],
			showLeadForm: false,
			showContactForm: false,
			showLeadTimelineForm: false,
			isClosed: false,
			overrideStep: null,

			lead: null,
			events: [],
		};
	},
	methods: {
		formatDate,
		formatAvatarName,
		getFileUrl,
		getFileName,
		patchLead,
		getContactIcon(contact) {
			switch (contact.subType) {
				case "phone":
					return contact.accepted ? "mdi-phone-in-talk" : "mdi-phone-missed";
				case "message":
					return contact.accepted ? "mdi-message-bulleted" : "mdi-message-bulleted-off";
				case "email":
					return contact.accepted ? "mdi-email" : "mdi-email-alert";
				case "socialNetwork":
					return "mdi-facebook-messenger";
			}
		},
		async handleGetLead() {
			const response = await getLead(this.$route.params.id);

			if (response.status === 200) {
				this.lead = response.data;
				this.events = [];

				const { _created, events, stage } = this.lead;

				this.events.push({
					color: "info",
					icon: "mdi-star",
					step: null,
					title: "Lead Criada",
					date: _created,
				});

				this.events = this.events.concat(
					events.map(e => {
						switch (e.type) {
							case "recruiter":
								switch (e.subType) {
									case "assignment":
										return {
											color: "info",
											icon: "mdi-account-switch",
											step: 1,
											title: `Atribuído a ${e.recruiter.name}`,
											date: e.date || _created,
										};
									case "reassignment":
										return {
											color: "info",
											icon: "mdi-account-switch",
											step: 1,
											title: `Reatribuído a ${e.recruiter.name}`,
											date: e.date || _created,
										};
									case "acceptance":
										return {
											color: e.accepted ? "success" : "error",
											icon: e.accepted ? "mdi-check" : "mdi-close",
											step: 1,
											title: e.accepted ? "Aceite" : "Recusado",
											date: e.date,
											observations: e.observations,
										};
									default:
										return;
								}
							case "contact":
								return {
									color: e.accepted && !e.declinedBy ? "success" : "error",
									icon: this.getContactIcon(e),
									step: 2,
									title: e.accepted
										? e.declinedBy
											? e.declinedBy === "lead"
												? "Recusado pelo Candidato"
												: "Recusado pelo Recrutador"
											: "Contactado"
										: "Não Contactado",
									date: e.date,
									justification:
										e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
									observations: e.observations,
									canDelete: true,
								};
							case "interview":
								switch (e.subType) {
									case "scheduled":
										return {
											color: "info",
											icon: "mdi-calendar-check",
											step: 3,
											title: `Entrevista marcada para ${formatDate(e.scheduledDate, "DD-MM-YYYY HH:mm")}`,
											date: e.date,
											observations: e.observations,
											canDelete: true,
										};
									case "missed":
										return {
											color: "error",
											icon: "mdi-calendar-remove",
											step: 3,
											title: "Entrevista não aconteceu",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									case "done":
										return {
											color: e.declinedBy ? "error" : "success",
											icon: e.declinedBy ? "mdi-account-remove" : "mdi-account-multiple",
											step: 4,
											title: e.declinedBy
												? e.declinedBy === "lead"
													? "Recusado pelo Candidato"
													: "Recusado pelo Recrutador"
												: "Entrevista",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									default:
										return;
								}
							case "presentation":
								switch (e.subType) {
									case "scheduled":
										return {
											color: "info",
											icon: "mdi-calendar-check",
											step: 3,
											title: `Apresentação do Projeto marcada para ${formatDate(
												e.scheduledDate,
												"DD-MM-YYYY HH:mm",
											)}`,
											date: e.date,
											observations: e.observations,
											canDelete: true,
										};
									case "missed":
										return {
											color: "error",
											icon: "mdi-calendar-remove",
											step: 3,
											title: "Apresentação do Projeto não aconteceu",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									case "done":
										return {
											color: e.declinedBy ? "error" : "success",
											icon: e.declinedBy ? "mdi-account-remove" : "mdi-account-multiple",
											step: 4,
											title: e.declinedBy
												? e.declinedBy === "lead"
													? "Recusado pelo Candidato"
													: "Recusado pelo Recrutador"
												: "Apresentação do Projeto",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									default:
										return;
								}
							case "approval":
								switch (e.subType) {
									case "scheduled":
										return {
											color: "info",
											icon: "mdi-calendar-check",
											step: 4,
											title: `Reunião de Aprovação marcada para ${formatDate(
												e.scheduledDate,
												"DD-MM-YYYY HH:mm",
											)}`,
											date: e.date,
											observations: e.observations,
											canDelete: true,
										};
									case "missed":
										return {
											color: "error",
											icon: "mdi-calendar-remove",
											step: 4,
											title: "Reunião de Aprovação não aconteceu",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									case "done":
										return {
											color: e.declinedBy ? "error" : "success",
											icon: e.declinedBy ? "mdi-account-remove" : "mdi-account-check",
											step: 5,
											title: e.declinedBy
												? e.declinedBy === "lead"
													? "Recusado pelo Candidato"
													: "Recusado pelo Recrutador"
												: "Reunião de Aprovação",
											date: e.date,
											justification:
												e.justification && this.config.justifications.find(j => j.key === e.justification)?.name,
											observations: e.observations,
											canDelete: true,
										};
									default:
										return;
								}
							case "recruited":
								return {
									color: "success",
									icon: "mdi-star",
									step: 6,
									title: "Recrutado",
									date: e.date,
								};
						}
					}),
				);

				if (
					[
						"toAssign",
						"recruiterRejected",
						"contactsContactedByRecruiter",
						"contactsContactedByLead",
						"interviewDoneByRecruiter",
						"interviewDoneByLead",
						"presentationDoneByRecruiter",
						"presentationDoneByLead",
						"approvalDoneByRecruiter",
						"approvalDoneByLead",
						"recruited",
					].includes(stage)
				) {
					this.isClosed = true;
				} else {
					this.isClosed = false;
				}

				this.events = this.events.reverse();
			} else {
				this.$router.replace("/leads");
			}
		},
		openLeadForm() {
			this.showLeadForm = true;
		},
		closeLeadForm() {
			this.handleGetLead();

			this.showLeadForm = false;
		},
		openContactForm() {
			this.showContactForm = true;
		},
		async handleAddContact(contactType, contacted, contactDeclinedBy, justification, observations, afterContact) {
			const response = await addEvents(this.lead._id, [
				{
					type: "contact",
					subType: contactType,
					date: Date.now(),
					accepted: contacted,
					observations,
					afterContact,
					recruiter: this.lead.recruiter,
				},
			]);

			if (response.status === 200) {
				this.handleGetLead();

				this.showContactForm = false;
			}
		},
		openLeadTimelineForm(step) {
			this.overrideStep = step;
			this.showLeadTimelineForm = true;
		},
		closeLeadTimelineForm() {
			this.handleGetLead();
		},
		async deleteLastEvent() {
			const response = await deleteLastEvent(this.lead._id);

			if (response.status === 200) {
				this.handleGetLead();
			}
		},
		async toggleAirTable(add) {
			const response = await toggleAirTable(this.lead._id, add);

			if (response.status === 200) {
				this.handleGetLead();
			}
		},
	},
});
</script>
